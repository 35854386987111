@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.l-lowerCaption__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 356px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden; }
  @media screen and (max-width: 768px) {
    .l-lowerCaption {
      height: 250px; } }
  .l-lowerCaption__title {
    color: #000000;
    max-width: 1170px;
    text-align: center;
    font-size: 36px;
    font-family: "Poppins", sans-serif;
    padding: 0 10px;
    width: 100%; }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__title {
        width: 100%;
        padding: 0 20px; } }
